<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M2.488,17.001c0.276,0,0.5-0.224,0.5-0.5V5.204c0-0.122,0.099-0.22,0.22-0.22H20.81
      c0.121,0,0.22,0.099,0.22,0.22v11.297c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V5.204c0-0.673-0.547-1.22-1.22-1.22H3.208
      c-0.673,0-1.22,0.547-1.22,1.22v11.297C1.988,16.777,2.211,17.001,2.488,17.001z"/>
    <path
      d="M23.505,18.03H13.248c-0.276,0-0.5,0.224-0.5,0.5l0.065,0.238l-1.543,0.065V18.53c0-0.276-0.224-0.5-0.5-0.5
      H0.512c-0.276,0-0.5,0.224-0.5,0.5v1.2c0,0.717,0.583,1.3,1.3,1.3h21.392c0.717,0,1.301-0.583,1.301-1.3v-1.2
      C24.005,18.254,23.781,18.03,23.505,18.03z M23.005,19.73c0,0.165-0.135,0.3-0.301,0.3H1.312c-0.166,0-0.3-0.135-0.3-0.3v-0.7
      h9.278c0.091,0.422,0.466,0.738,0.914,0.738h1.608c0.449,0,0.824-0.316,0.915-0.738h9.277v0.7H23.005z"/>
    <path
      d="M17.814,11.139c0.641,0,1.162-0.517,1.162-1.153s-0.521-1.153-1.162-1.153s-1.161,0.518-1.161,1.153
      c0,0.15,0.034,0.292,0.086,0.423L14.25,13.09c-0.106-0.032-0.212-0.066-0.328-0.066c-0.049,0-0.091,0.022-0.139,0.028l-2.773-4.23
      c0.105-0.174,0.182-0.365,0.182-0.582c0-0.636-0.521-1.153-1.162-1.153S8.87,7.604,8.87,8.24c0,0.044,0.02,0.081,0.025,0.123
      L6.736,9.719C6.559,9.611,6.363,9.531,6.14,9.531c-0.641,0-1.162,0.517-1.162,1.153s0.521,1.153,1.162,1.153
      s1.162-0.518,1.162-1.153c0-0.044-0.02-0.081-0.025-0.123l2.158-1.356c0.177,0.109,0.374,0.188,0.597,0.188
      c0.05,0,0.092-0.022,0.14-0.028l2.773,4.23c-0.105,0.174-0.182,0.365-0.182,0.582c0,0.636,0.521,1.153,1.161,1.153
      s1.162-0.518,1.162-1.153c0-0.15-0.034-0.292-0.086-0.424l2.489-2.68C17.593,11.105,17.698,11.139,17.814,11.139z M17.814,9.833
      c0.09,0,0.162,0.069,0.162,0.153c0.001,0.168-0.322,0.17-0.323,0C17.653,9.902,17.726,9.833,17.814,9.833z M5.978,10.684
      c0-0.084,0.072-0.153,0.162-0.153s0.162,0.068,0.162,0.153C6.301,10.853,5.978,10.853,5.978,10.684z M10.031,8.086
      c0.089,0,0.162,0.069,0.162,0.153c0,0.169-0.323,0.169-0.323,0C9.87,8.155,9.942,8.086,10.031,8.086z M13.762,14.177
      c0-0.085,0.072-0.153,0.161-0.153c0.09,0,0.162,0.068,0.162,0.153C14.085,14.347,13.763,14.348,13.762,14.177z"/>
  </svg>
</template>
